<template>
    <c-alert
        v-if="shouldRender"
        :color="color"
        class="flash d-flex align-items-start align-items-sm-center justify-content-between"
        style="gap: 5px"
    >
        <dynamic-content :content="content" />
        <a
            v-if="isSticky"
            class="flash-close btn btn-icon text-success-emphasis"
            href="#"
            @click.prevent="shouldRender = false"
        >
            <icon name="cil-x-circle" />
        </a>
    </c-alert>
</template>
<script>
import { compile, h } from 'vue';

// Component to render dynamic templates
const DynamicContent = {
    props: ['content'],
    render: function () {
        return h(compile(this.content));
    },
};

export default {
    components: {
        DynamicContent,
    },
    data() {
        return {
            shouldRender: false,
            timeout: null,
        };
    },
    computed: {
        color() {
            return this.isSuccessMessage ? 'success' : 'danger';
        },
        flash() {
            return this.$page.props.flash;
        },
        isSuccessMessage() {
            return (
                this.flash &&
                this.flash.message &&
                this.flash.message.length > 0
            );
        },
        isSticky() {
            return !!this.flash.sticky;
        },
        isErrorMessage() {
            return (
                this.flash && this.flash.error && this.flash.error.length > 0
            );
        },
        content() {
            const message = this.isSuccessMessage
                ? this.flash.message
                : this.flash.error;

            return !!message ? '<div>' + message + '</div>' : null;
        },
        hasFlashMessage() {
            return this.isSuccessMessage || this.isErrorMessage;
        },
    },
    watch: {
        flash: {
            handler: function (newValue) {
                this.shouldRender = !!newValue.message || !!newValue.error;
                clearTimeout(this.timeout);

                if (!this.isSticky) {
                    this.timeout = setTimeout(() => {
                        this.shouldRender = false;
                    }, 5000);
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
