<template>
    <app-layout>
        <row>
            <column md="6">
                <jet-form-section @submitted="handleSubmit($event)">
                    <template #title>
                        <div v-if="isUpdateForm">
                            {{
                                __('users.edit_user.title', { name: form.name })
                            }}
                        </div>
                        <div v-else>
                            {{ __('users.create_user.title') }}
                        </div>
                    </template>

                    <template #form>
                        <jet-input
                            v-model="form.firstName"
                            :autofocus="true"
                            :invalidFeedback="form.errors.firstName"
                            :label="
                                __('users.edit_user.field.first_name.label')
                            "
                            :readonly="!isEditable"
                            :required="true"
                        />
                        <jet-input
                            v-model="form.lastName"
                            :invalidFeedback="form.errors.lastName"
                            :label="__('users.edit_user.field.last_name.label')"
                            :readonly="!isEditable"
                            :required="true"
                            icon="cil-qr-code"
                        />
                        <jet-input
                            v-model="form.email"
                            :invalidFeedback="form.errors.email"
                            :label="__('users.edit_user.field.email.label')"
                            :readonly="!isEditable"
                            :required="true"
                        />
                        <jet-select
                            v-if="availableRoles.length > 0"
                            v-model="form.role"
                            :invalidFeedback="form.errors.role"
                            :label="__('users.edit_user.field.role.label')"
                            :options="availableRoles"
                            :readonly="!isEditable"
                            :required="true"
                        />
                        <multi-select-dropdown
                            v-if="hasAnyPermission('location.assign_user')"
                            v-model="form.locations"
                            :disabled="!isEditable"
                            :invalid-feedback="form.errors.locations"
                            :is-form-field="true"
                            :label="__('users.edit_user.field.airports.label')"
                            :options="locations"
                            :placeholder="
                                __('users.edit_user.field.airports.placeholder')
                            "
                            :required="true"
                            :show-searchbar="true"
                            :show-select-all-button="false"
                        >
                            <template v-slot:label="{ option }">
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                >
                                    <span class="mr-3">{{ option.label }}</span
                                    ><small class="text-muted">{{
                                        option.iataCode
                                    }}</small>
                                </div>
                            </template>
                        </multi-select-dropdown>
                        <jet-select
                            v-model="form.language"
                            :invalidFeedback="form.errors.language"
                            :label="__('users.edit_user.field.language.label')"
                            :options="supportedLanguages"
                            :resettable="false"
                        />
                    </template>

                    <template #actions>
                        <jet-form-buttons
                            :cancelRoute="$route('users.index')"
                            :disabled="!isFormSubmittable"
                            :is-processing="form.processing"
                            :isUpdateForm="isUpdateForm"
                            :primary-action-label="primaryActionLabel"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import { getLabelForLocale } from '@/Utils/getLabelForLocale';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';

export default {
    components: {
        MultiSelectDropdown,
        AppLayout,
        JetFormButtons,
        JetInput,
        JetFormSection,
        JetSelect,
    },

    data() {
        return {
            form: this.$inertia.form({
                firstName: '',
                lastName: '',
                email: '',
                role: '3',
                language: null,
                locations: null,
            }),
        };
    },

    computed: {
        user() {
            return this.$page.props.user;
        },
        isUpdateForm() {
            return !!this.user;
        },
        isEditable() {
            return !this.isUpdateForm || this.$page.props.editable;
        },
        availableRoles() {
            return this.$page.props.assignableRoles.data.map((role) => ({
                value: role.id.toString(),
                label: role.name.toUpperCase(),
            }));
        },
        defaultUserRole() {
            return this.$page.props.defaultUserRole;
        },
        defaultLanguage() {
            return this.$page.props.locale;
        },
        supportedLanguages() {
            return this.$page.props.supported_locales.map((locale) => ({
                value: locale,
                label: getLabelForLocale(locale),
            }));
        },
        locations() {
            return (this.$page.props.locations?.data || []).map((loc) => ({
                label: loc.name,
                value: loc.id,
                iataCode: loc.iataCode,
                searchableContent: `${loc.iataCode}|${loc.icaoCode}|${loc.name}`,
            }));
        },
        primaryActionLabel() {
            return this.isUpdateForm
                ? this.__('general.action.save')
                : this.__('general.action.add');
        },
    },

    mounted() {
        if (this.isUpdateForm) {
            this.form = this.$inertia.form({
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                email: this.user.email,
                role: this.user.roles.at(0)?.id.toString(),
                language: this.user.language || this.defaultLanguage,
                locations: (this.user.locations || []).map((loc) => loc.id),
            });
        } else {
            this.form.language = this.defaultLanguage;
            this.form.role = this.defaultUserRole.id.toString();
        }
    },

    methods: {
        handleSubmit(event) {
            if (this.isUpdateForm) {
                this.updateUser();
            } else {
                this.createUser();
            }
        },
        createUser() {
            this.form
                .transform(this.transformValues)
                .post(route('users.store'));
        },
        updateUser() {
            this.form
                .transform(this.transformValues)
                .put(route('users.update', { user: this.user }));
        },
        transformValues(data) {
            return {
                ...data,
                role: parseInt(data.role),
            };
        },
        getCancelRoute() {
            return this.isUpdateForm
                ? route('users.show', this.user.id)
                : route('users.index');
        },
    },
};
</script>
