<template>
    <div
        ref="sheet"
        :class="{ 'bg-white': isSticky, 'border-top': isSticky }"
        class="d-flex flex-column d-sm-none justify-content-end align-items-stretch sticky-bottom z-10"
        style="gap: 10px; margin: 0 -12px; padding: 12px"
    >
        <slot />
    </div>
    <div ref="sentinel" class="sticky-sentinel" style="height: 1px" />
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue';

const sentinel = useTemplateRef('sentinel');
const isSticky = ref(false);
const observer = new IntersectionObserver(
    ([entry]) => {
        // Check if the sentinel is intersecting (in view)
        isSticky.value = !entry.isIntersecting;
    },
    { threshold: [0] }, // Trigger when sentinel goes in/out of view
);

onMounted(() => {
    observer.observe(sentinel.value);
});

onBeforeUnmount(() => {
    observer.disconnect();
});
</script>
