<template>
    <app-layout>
        <page-header title="Inoperable Assets">
            <div class="d-sm-flex justify-content-between align-items-start">
                <reporting-filter-bar
                    key="filter-bar-1"
                    :is-processing="isProcessing"
                    @change="handleFilterChange"
                />
                <jet-button
                    :disabled="true"
                    class="d-none d-sm-flex"
                    color="secondary"
                    >{{ __('general.action.export') }}
                </jet-button>
            </div>
        </page-header>
        <row>
            <column>
                <card>
                    <template #header>
                        <b>Inoperable Equipment Report</b>
                    </template>
                    <c-alert v-if="missingLocations.length" color="info">
                        <div>
                            The report(s) for the following location(s) are not
                            available yet:
                        </div>
                        <ul class="list-unstyled">
                            <li v-for="location in missingLocations">
                                <div
                                    class="d-flex align-items-center"
                                    style="gap: 10px"
                                >
                                    <icon icon="cil-clock" />
                                    {{ location }}
                                </div>
                            </li>
                        </ul>
                    </c-alert>
                    <div
                        class="d-flex justify-content-between align-items-start pb-2"
                    >
                        <report-meta-info
                            :report="report"
                            :total-assets-count="totalAssetsCount"
                        />
                        <div
                            v-if="report.type === 'daily'"
                            class="d-none d-sm-block"
                        >
                            <small class="text-muted"
                                >{{
                                    __('assets.overview.results.count', {
                                        assets_count: `${report.inoperableAssets.data.length}/${report.inoperableAssets.meta.total}`,
                                    })
                                }}
                            </small>
                        </div>
                    </div>
                    <daily-light-report-table
                        v-if="report?.type === 'daily'"
                        @sort="handleSort"
                    />
                    <weekly-light-report-table v-else />
                </card>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import ReportingFilterBar from '@/Pages/ReportingLight/ReportingFilterBar.vue';
import DailyLightReportTable from '@/Pages/ReportingLight/DailyLightReportTable.vue';
import WeeklyLightReportTable from '@/Pages/ReportingLight/WeeklyLightReportTable.vue';
import ReportMetaInfo from '@/Pages/ReportingLight/ReportMetaInfo.vue';

import Card from '@/Components/Card.vue';
import PageTitle from '@/Components/PageTitle.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    name: 'ReportingLight',
    components: {
        PageHeader,
        PageTitle,
        Card,
        ReportMetaInfo,
        WeeklyLightReportTable,
        DailyLightReportTable,
        ReportingFilterBar,
        JetButton,
        AppLayout,
    },
    data() {
        return {
            activeFilters: { type: 'daily', locations: [] },
            isProcessing: false,
        };
    },
    computed: {
        totalAssetsCount() {
            return this.$page.props.totalAssetsCount || 0;
        },
        report() {
            return this.$page.props.report;
        },
        missingLocations() {
            return [];
        },
        availableLocationIds() {
            return this.$page.props.reportAvailableForLocationIds;
        },
    },
    methods: {
        handleFilterChange(filters) {
            this.activeFilters = filters;
            this.loadData();
        },
        handleSort(sort) {
            this.activeFilters.sort = sort;
            this.loadData();
        },
        transformSortToQuery(sort) {
            if (!sort) {
                return undefined;
            }

            if (this.activeFilters.type === 'weekly') {
                return undefined;
            }

            let sortDirection = sort.asc ? 'asc' : 'desc';
            return `${sort.column}.${sortDirection}`;
        },
        loadData() {
            this.isProcessing = true;

            let params = Object.assign({}, this.activeFilters, {
                sort: this.transformSortToQuery(this.activeFilters.sort),
            });

            this.$inertia.get(this.route('reporting-light.index'), params, {
                only: ['totalAssetsCount', 'availableReportDates', 'report'],
                preserveState: true,
                onFinish: () => {
                    this.isProcessing = false;
                },
            });
        },
    },
};
</script>
